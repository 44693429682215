#settings {
  position: relative;
  overflow-y: auto;
  width: 100%;

  .icon-wrap {
    display: flex;
    align-items: center;
    border: 1px solid $grey-1;
    border-radius: 5px;
    padding: 5px;
    color: $grey-1;
    width: max-content;
    font-size: 1rem;
  }

  .content-wrap {
    width: 100%;
    display: flex;
    min-height: 700px;

    .content-container {
      width: 100%;
    }
  }

  .settings-container {
    width: 100%;
    padding: 15px;
    @include fade-in(0.5s);

    &[data-active='false'] {
      display: none;
    }

    .top {
      .title {
        font-size: 1.2rem;
        font-weight: 500;
        color: $grey-1;
      }
    }
  }

  .bottom-content {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
}

/* common settings */
@import './did-resolver';

/* rp settings */
@import './rp/request-info';
@import './rp/optional-params';
@import './rp/request-vp';
@import './rp/static-metadata';

/* op settings */
@import './op/signing-params';
@import './op/verifiable-presentation';

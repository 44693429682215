#settings-popup {
  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    .button {
      width: 100%;
      max-width: 200px;
    }
  }
}

.side-navbar {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  max-width: 250px;
  padding: 10px 0 20px 0;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  transition: all 0.7s ease;
  transform: translateX(0);
  background-color: $white;
  z-index: 2;
  border-bottom-left-radius: 10px;

  &[data-active='false'] {
    transform: translateX(-300px);
    width: 0;
  }

  .sections-wrap {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    transition: all 0.5s ease;
  }

  .section {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 250px;

    .title {
      padding: 15px;
      color: $theme;
    }

    .category {
      color: $grey-1;
      font-weight: 500;
      text-decoration: none;
      transition: all 0.5s ease;
      max-width: 90%;
      border-radius: 0 10px 10px 0;
      display: flex;
      align-items: center;
      cursor: pointer;

      .value {
        padding: 20px;
      }

      &::before {
        content: '';
        display: inline-block;
        width: 0px;
        height: 40px;
        background: $theme;
        border-radius: 0 10px 10px 0;
        transition: all 0.4s ease;
      }

      &.active {
        background-color: #24c3b514;
        color: $theme;

        &::before {
          width: 7px;
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .side-navbar {
    position: absolute;
  }
}

.setup-extension-info-popup {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .texts {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .buttons-wrap {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
    }
}

$theme: #25c3b5;
$white: #ffffff;
$light: #f9f9f9;
$red: #f53d3d;
$black: #000000;
$dark-1: #2c3039;
$grey-1: #5e6f81;
$grey-1-light: #5e6f8150;

.theme {
    color: $theme !important;
}
.red {
    color: $red !important;
}
.white {
    color: $white !important;
}
.light {
    color: $light !important;
}
.black {
    color: $black !important;
}
.dark-1 {
    color: $dark-1 !important;
}
.grey-1 {
    color: $grey-1 !important;
}
.grey-1-light {
    color: $grey-1-light !important;
}

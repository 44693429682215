#rp-static-metadata {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .settings {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .setting {
            display: grid;
            grid-template-columns: 40% 40% 15%;
            gap: 15px;
            padding: 10px 20px 10px 20px;
            background-color: $light;
            border-radius: 7px;

            .section {
                display: flex;
                align-items: center;
                gap: 25px;

                .value, .text {
                    word-break: break-all;
                }

                &:nth-child(2), &:nth-child(3) {
                    justify-content: flex-end;
                }

                .icon {
                    font-size: 1rem;
                }
            }
        }
    }

    .button-wrap{
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
}

@media screen and (max-width: 960px) {
    #rp-static-metadata {
        .settings {
            .setting {
                display: flex;
                flex-direction: column;
            }
        }
    }
}
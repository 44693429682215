#op-signing-params {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .inputs {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}
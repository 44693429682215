.navbar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    min-height: 70px;
    padding: 10px 20px 10px 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

    .section {
        &[data-active="false"] {
            display: none;
        }
    }

    .left {
        .title-wrap {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 10px;

            &[data-clickable="true"] {
                cursor: pointer;
            }

            span {
                padding: 2px;
                display: flex;
                border-radius: 5px;
                border: 1px solid #00000033;
            }
        }
    }
}
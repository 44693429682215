#home {
    .navbar-button {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;
    }

    #did-siop-setting-button {
        display: none;
    }

    .settings {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 20px;

        .setting {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            gap: 20px;
            padding: 10px 20px 10px 20px;
            background-color: $light;
            border-radius: 7px;

            .section {
                display: flex;
                align-items: center;
                gap: 25px;

                .text {
                    min-width: 60px;
                    cursor: url('../../images/cursor-edit.svg'), pointer;
                }

                .icon {
                    font-size: 1rem;
                }
            }
        }
    }
}
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@import './colors';
@import './animation';

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html,
body {
    font-family: 'Roboto', sans-serif;
    background-color: $dark-1;
    padding: 20px;
    width: 100%;
    min-height: 100vh;
    overflow-x: hidden;
    cursor: default;
}

.app {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;

    #main-title {
        font-weight: 500;
        font-size: 2rem;
        text-align: center;
    }
}

.main-container {
    width: 100%;
    max-width: 1000px;
    min-height: 600px;
    border-radius: 10px;
    background-color: $white;
}

.bottom-container {
    width: 100%;
    max-width: 1000px;
    border-radius: 10px;
    background-color: $white;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
    color: $grey-1;
    font-weight: 500;
}

.button {
    &:not([disabled]) {
        color: $theme !important;

        &.bg-themed {
            background-color: $theme !important;
            color: $white !important;
        }

        &.bg-grey {
            background-color: $grey-1 !important;
            color: $white !important;
        }
    }

    &[disabled] {
        cursor: not-allowed;

        &.bg-themed {
            background-color: rgb(107, 107, 107);
        }
    }

    &.h-max-content {
        width: max-content;
    }

    &.w-max-content {
        width: max-content;
    }
}

.input {
    width: 100%;

    &.dark {
        background-color: rgba(0, 0, 0, 0.1);
    }

    &.light {
        background-color: $light;
    }
}

.input-wrap {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;

    .title-wrap {
        display: flex;
        align-items: center;
        gap: 10px;

        .icon {
            color: $theme;
            font-size: 0.8rem;
        }
    }

    .title {
        color: $grey-1;
    }
}

.Mui-focused fieldset {
    border-color: $theme !important;
}

@import './layout/layout';
@import './pages/pages';
@import './components/components';

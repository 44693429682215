#rp-request-info {
    display: flex;
    flex-direction: column;
    gap: 30px;
    
    .inputs {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}
#rp-optional-params {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .settings {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .setting {
            display: flex;
            gap: 15px;
            width: 100%;

            .section {
                display: flex;
                gap: 15px;

                &:nth-child(1) {
                    width: 100%;
                }
            }
        }
    }

    .button {
        display: flex;
        align-items: center;
        gap: 7px;
    }
}

@media screen and (max-width: 960px) {
    #rp-optional-params {
        .settings {
            .setting {

                .section {
                    &:nth-child(1) {
                        flex-direction: column;
                    }
                }
            }
        }
    }
}
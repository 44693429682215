.did-resolver {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .top-container {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .selected-resolver {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .resolvers {
      display: flex;
      flex-direction: column;
      gap: 7px;
      border-radius: 7px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 10px;
      min-height: 400px;
      background-color: #f9f9f9;

      .resolver {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 15px;
        justify-content: space-between;
        padding: 5px 0px 10px 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        .section:nth-child(1) {
          max-width: 300px;
          width: 100%;
        }

        .section:nth-child(2) {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          align-items: center;
          max-width: 170px;
          justify-content: space-between;

          .close-icon {
            font-size: 1rem;
            display: flex;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 770px) {
  .did-resolver {
    .top-container {
      flex-wrap: wrap;
    }
  }
}

.crypto-suites-popup-container {
  .crypto-suites-title {
    display: flex;
    align-items: center;
    width: 100%;

    .title {
      width: 100%;
      text-align: center;
    }

    .title-back {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.5rem;
      justify-self: flex-start;
      visibility: visible;
      opacity: 1;
      transition: all 0.4s ease;
      color: $theme;
      cursor: pointer;

      &[data-active='false'] {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}

.customized-dialog-title-wrap {
  display: flex;
  align-items: center;

  .customized-dialog-title-icon {
    color: $theme;
  }

  .customized-dialog-title-text {
    color: $theme;
    width: 100%;
    text-align: center;
    font-size: 1rem;
  }
}

@import './containers/containers';

@mixin fade-in($duration: 1s) {
    -webkit-animation: fade-in $duration cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in $duration cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
.crypto-suites-popup {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;

  .form {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    display: none;
    @include fade-in(0.6s);

    &.active {
      display: flex;
    }

    .radio-btn-wrap {
      background: rgba(0, 0, 0, 0.1);
      border: 1.5px solid rgba(0, 0, 0, 0.1);
      border-radius: 5px;

      .radio-btn-label {
        padding: 10px 20px 10px 5px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        margin-left: 0;
        margin-right: 0;
        color: $grey-1;

        &:last-child {
          border-color: transparent;
        }
      }
    }
  }

  .button {
    width: max-content;

    &.limit-width {
      width: 100%;
      max-width: 170px;
    }

    &.new-suite {
      display: flex;
      align-items: center;
      gap: 10px;

      .icon {
        font-size: 1.4rem;
      }
    }
  }
}
